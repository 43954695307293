import "./Contacts.css";
import React from "react";
import useWindowSize from "../../hooks/useWindowSize";

const Contacts = () => {
  const size = useWindowSize();

  return (
    <section className="contacts">
      <h2 className="contacts__title">
        СОДА готова разработать и реализовать интерьер любой сложности
      </h2>

      <div className="contacts__container">
        <div className="contacts__card">
          <h3 className="contacts__subtitle">Контакты</h3>
          <div className="contacts__links">
            <a
              className="contacts__link"
              href="tel:89335672715"
              target="_blank"
              rel="noreferrer"
            >
              +7 (812) 716-67-76
            </a>
            <a
              className="contacts__link"
              href="mailto:soda@penta.pro"
              target="_blank"
              rel="noreferrer"
            >
              soda@penta.pro
            </a>
          </div>
          <p className="contacts__text">
            197101, Санкт-Петербург, <br /> Большой проспект П. С. 65
          </p>
          <p className="contacts__text">
            Мы работаем в будние дни <br /> с 10:00 до 20:00
          </p>
        </div>
        {size.width > 576 && (
          <iframe
            className="contacts__map"
            src="https://yandex.ru/map-widget/v1/?um=constructor%3Ac5631b23a19596a2f71dce3d364ea86179ef1346b1fa040b4c077ac177720142&amp;source=constructor&theme=dark"
            frameborder="0"
          ></iframe>
        )}
        {size.width < 576 && (
          <iframe
            className="contacts__map"
            src="https://yandex.ru/map-widget/v1/?um=constructor%3Adc113ed74936027bf699d002918a083a820660e46b04a9e97409440cbff8181e&amp;source=constructor&theme=dark"
            frameborder="0"
          ></iframe>
        )}
      </div>
    </section>
  );
};

export default Contacts;
