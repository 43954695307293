import "./Popup.css";
import React, { useState, useEffect, useRef } from "react";

import useFormsValidation from "../../hooks/useFormsValidation";
import usePhoneMask from "../../hooks/usePhoneMask";

import closeIcon from "../../images/Main/closeIcon.svg";
import InputWithError from "../InputWithError/InputWithError";

function Popup({ onOverlay, isOpen, onClose, popupParam, isInputsDisabled }) {
  const {
    handleCheckValidity,
    handleChangeInput,
    inputs,
    setInputs,
    isError,
    messageError,
    isValidity,
  } = useFormsValidation({});
  const { onPhonePaste, onPhoneInput, onPhoneKeyDown } =
    usePhoneMask(handleChangeInput);
  const formRef = useRef();

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setMounted(true);
    }
  }, [isOpen]);

  function handleSubmit(e) {
    e.preventDefault();

    // Динамически формируем объект только с непустыми значениями
    const requestData = {
      pageUrl: window.location.pathname,
      ...(inputs.name && { name: inputs.name }),
      ...(inputs.phone && { phone: inputs.phone }),
      ...(inputs.area && { area: `${inputs.area} м²` }),
    };

    fetch("https://sodadesign.pro/api/send-feedback", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Ошибка при отправке данных");
        }
      })
      .then((data) => {
        console.log("Успешно отправлено:", data);
        setInputs({ name: "", phone: "", area: "" }); // Сбрасываем форму
        handleCheckValidity(false);
        onClose();
      })
      .catch((error) => {
        console.error("Ошибка при отправке запроса:", error);
      });
  }

  return (
    <div
      className={`popup ${isOpen ? "popup_active" : mounted && "popup_closed"}`}
      onClick={onOverlay}
    >
      <div className="popup__container">
        <h2 className="popup__title">{popupParam.title}</h2>
        <p className="popup__text">{popupParam.text}</p>
        <form
          className="popup__form"
          action="#"
          name="calc-popup-form"
          onSubmit={handleSubmit}
          ref={formRef}
          noValidate
        >
          {popupParam.inputs.includes("name") && (
            <InputWithError
              name="name"
              value={inputs.name}
              onChange={handleChangeInput}
              placeholder="Ваше имя"
              pattern="^[\-\sa-zA-Zа-яА-Я]*$"
              isError={isError.name}
              messageError={messageError.name}
              isInputsDisabled={isInputsDisabled}
              required={false}
            />
          )}
          {popupParam.inputs.includes("phone") && (
            <InputWithError
              name="phone"
              value={inputs.phone}
              onChange={onPhoneInput}
              onPaste={onPhonePaste}
              onKeyDown={onPhoneKeyDown}
              placeholder="Телефон для связи"
              pattern="^(\+7|8)\s?\(\d{3}\)\s?\d{3}-\d{2}-\d{2}$"
              isError={isError.phone}
              messageError={messageError.phone}
              isInputsDisabled={isInputsDisabled}
              required={true}
            />
          )}
          {popupParam.inputs.includes("area") && (
            <InputWithError
              name="area"
              value={inputs.area}
              onChange={handleChangeInput}
              placeholder="Площадь квартиры"
              pattern="[0-9]*"
              isError={isError.area}
              messageError={messageError.area}
              isInputsDisabled={isInputsDisabled}
              required={false}
            />
          )}
          <button
            className={`${isValidity ? "" : "button_disabled"} button`}
            type="submit"
            style={{ width: "100%" }}
            disabled={!isValidity}
          >
            Отправить заявку
          </button>
        </form>
        <button type="button" className="popup__close-button" onClick={onClose}>
          <img src={closeIcon} alt="Кнопка крестик" />
        </button>
      </div>
    </div>
  );
}

export default Popup;
