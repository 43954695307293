import "./Navigation.css";
import React, { useState } from "react";
import { Link } from "react-router-dom";

function Navigation({ setOpen }) {
  const [servicesOpen, setServicesOpen] = useState(false);
  const [galleryOpen, setGalleryOpen] = useState(false);

  // Функция для закрытия окна при клике на ссылку
  const handleLinkClick = () => {
    setServicesOpen(false);
    setGalleryOpen(false);
    setOpen();
  };

  return (
    <nav className="navigation">
      <ul className="navigation__list">
        <li className="navigation__element" onClick={setOpen}>
          <div className="navigation__el">
            <Link to="/about-us" className="navigation__link">
              О НАС
            </Link>
          </div>
        </li>

        {/* Галерея работ */}
        <li
          className="navigation__element"
          onMouseEnter={() => setGalleryOpen(true)}
          onMouseLeave={() => setGalleryOpen(false)}
        >
          <div className="navigation__hover-area">
            <div className="navigation__el">
              <Link
                to="/gallery"
                className="navigation__link"
                onClick={handleLinkClick}
              >
                ГАЛЕРЕЯ РАБОТ
              </Link>
            </div>
            <div className="navigation__sublinks">
              <Link
                to="/gallery/realized"
                className="navigation__sublink"
                onClick={handleLinkClick}
              >
                Реализованные проекты
              </Link>
              <Link
                to="/gallery/design"
                className="navigation__sublink"
                onClick={handleLinkClick}
              >
                Дизайн интерьеров квартир
              </Link>
            </div>
            <div
              className={`navigation__dropdown ${
                galleryOpen ? "navigation__dropdown_active" : ""
              }`}
            >
              <Link
                to="/gallery/realized"
                className="navigation__link"
                onClick={handleLinkClick}
              >
                Реализованные проекты
              </Link>
              <Link
                to="/gallery/design"
                className="navigation__link"
                onClick={handleLinkClick}
              >
                Дизайн интерьеров квартир
              </Link>
            </div>
          </div>
        </li>

        {/* Услуги */}
        <li
          className="navigation__element"
          onMouseEnter={() => setServicesOpen(true)}
          onMouseLeave={() => setServicesOpen(false)}
        >
          <div className="navigation__hover-area">
            <div className="navigation__el">
              <span className="navigation__link" style={{ cursor: "default" }}>
                УСЛУГИ
              </span>
            </div>
            <div className="navigation__sublinks">
              <Link
                to="/services/apartments"
                className="navigation__sublink"
                onClick={handleLinkClick}
              >
                Дизайн интерьера квартиры <br /> “Под ключ”
              </Link>
              <Link
                to="/services/houses"
                className="navigation__sublink"
                onClick={handleLinkClick}
              >
                Дизайн загородных домов <br /> “Под ключ”
              </Link>
              <Link
                to="/services/contracting"
                className="navigation__sublink"
                onClick={handleLinkClick}
              >
                Генеральный подряд
              </Link>
              <Link
                to="/services/supervision"
                className="navigation__sublink"
                onClick={handleLinkClick}
              >
                Авторский надзор
              </Link>
              <Link
                to="/services/renovation"
                className="navigation__sublink"
                onClick={handleLinkClick}
              >
                Ремонт квартир
              </Link>
            </div>
            <div
              className={`navigation__dropdown ${
                servicesOpen ? "navigation__dropdown_active" : ""
              }`}
            >
              <Link
                to="/services/apartments"
                className="navigation__link"
                onClick={handleLinkClick}
              >
                Дизайн интерьера квартиры “Под ключ”
              </Link>
              <Link
                to="/services/houses"
                className="navigation__link"
                onClick={handleLinkClick}
              >
                Дизайн загородных домов “Под ключ”
              </Link>
              <Link
                to="/services/contracting"
                className="navigation__link"
                onClick={handleLinkClick}
              >
                Генеральный подряд
              </Link>
              <Link
                to="/services/supervision"
                className="navigation__link"
                onClick={handleLinkClick}
              >
                Авторский надзор
              </Link>
              <Link
                to="/services/renovation"
                className="navigation__link"
                onClick={handleLinkClick}
              >
                Ремонт квартир
              </Link>
            </div>
          </div>
        </li>

        <li className="navigation__element" onClick={setOpen}>
          <div className="navigation__el">
            <Link to="/price" className="navigation__link">
              ЦЕНЫ
            </Link>
          </div>
        </li>

        <li className="navigation__element" onClick={setOpen}>
          <div className="navigation__el">
            <Link to="/contacts" className="navigation__link">
              КОНТАКТЫ
            </Link>
          </div>
        </li>
      </ul>
    </nav>
  );
}

export default Navigation;
