import townhouseImage from "../images/Main/townhouse.png";
import royalImage from "../images/gallery/royal-capital/intro.jpg";
import familiaImage from "../images/gallery/familia/intro.jpg";
import holidayImage from "../images/gallery/holiday/intro.jpg";
import leoImage from "../images/Main/Leo.jpg";
import mirImage from "../images/Main/first-direction.png";
import aristoImage from "../images/Main/Aristo.jpg";
import pushkinImage from "../images/Main/pushkin.jpg";

// team

import worker1 from "../images/Team/worker-1.png";
import worker2 from "../images/Team/worker-2.png";
import worker3 from "../images/Team/worker-3.png";
import worker4 from "../images/Team/worker-4.png";
import worker5 from "../images/Team/worker-5.png";
import worker6 from "../images/Team/worker-6.png";
import worker7 from "../images/Team/worker-7.png";
import worker8 from "../images/Team/worker-8.png";
import worker9 from "../images/Team/worker-9.png";
import worker10 from "../images/Team/worker-10.png";
import worker11 from "../images/Team/worker-11.png";
import worker12 from "../images/Team/worker-12.png";
import worker13 from "../images/Team/worker-13.png";
import worker14 from "../images/Team/worker-14.png";
import worker15 from "../images/Team/worker-15.png";
import worker16 from "../images/Team/worker-16.png";
import worker17 from "../images/Team/worker-17.png";
import worker18 from "../images/Team/worker-18.png";
import worker19 from "../images/Team/worker-19.png";
import worker20 from "../images/Team/worker-20.png";
import worker21 from "../images/Team/worker-21.png";

// TeamGood

import workerGood1 from "../images/TeamGood/worker-1.png";
import workerGood2 from "../images/TeamGood/worker-2.png";
import workerGood3 from "../images/TeamGood/worker-3.png";
import workerGood4 from "../images/TeamGood/worker-4.png";
import workerGood5 from "../images/TeamGood/worker-5.png";
import workerGood6 from "../images/TeamGood/worker-6.png";
import workerGood7 from "../images/TeamGood/worker-7.png";
import workerGood8 from "../images/TeamGood/worker-8.png";
import workerGood9 from "../images/TeamGood/worker-9.png";
import workerGood10 from "../images/TeamGood/worker-10.png";
import workerGood11 from "../images/TeamGood/worker-11.png";
import workerGood12 from "../images/TeamGood/worker-12.png";
import workerGood13 from "../images/TeamGood/worker-13.png";
import workerGood14 from "../images/TeamGood/worker-14.png";
import workerGood15 from "../images/TeamGood/worker-15.png";
import workerGood16 from "../images/TeamGood/worker-16.png";
import workerGood17 from "../images/TeamGood/worker-17.png";
import workerGood18 from "../images/TeamGood/worker-18.png";
import workerGood19 from "../images/TeamGood/worker-19.png";
import workerGood20 from "../images/TeamGood/worker-20.png";
import workerGood21 from "../images/TeamGood/worker-21.png";

// patterns

import pattern1 from "../images/contracting/2.jpg";
import pattern2 from "../images/contracting/2.jpg";
import pattern3 from "../images/contracting/3.jpg";
import pattern4 from "../images/contracting/4.jpg";
import pattern5 from "../images/contracting/5.jpg";
import pattern6 from "../images/contracting/6.jpg";
import pattern7 from "../images/contracting/7.jpg";
import pattern8 from "../images/contracting/8.jpg";
import pattern9 from "../images/contracting/8.jpg";

// slider

import plan from "../images/slider/plan.png";

const importAll = (r) =>
  r.keys().reduce((acc, item) => {
    acc[item.replace("./", "")] = r(item);
    return acc;
  }, {});

const extractNumber = (filename) => {
  const match = filename.match(/(\d+)/);
  return match ? parseInt(match[0], 10) : Infinity;
};

const filterImages = (images, exclude) =>
  Object.keys(images)
    .filter((image) => !exclude.includes(image))
    .sort((a, b) => extractNumber(a) - extractNumber(b))
    .map((key) => images[key]);

const plans = importAll(
  require.context("../images/plans", false, /\.(png|jpe?g)$/)
);

const drawings = importAll(
  require.context("../images/drawings", false, /\.(png|jpe?g)$/)
);

const visualizations = importAll(
  require.context("../images/visualizations", false, /\.(png|jpe?g)$/)
);

const specification = importAll(
  require.context("../images/specification", false, /\.(png|jpe?g)$/)
);

export const works = [
  {
    id: 1,
    metrs: "129 м²",
    city: "Санкт-Петербург",
    name: "ЖК «Царская столица»",
    background: royalImage,
    link: "/gallery/royal-capital",
  },
  {
    id: 2,
    metrs: "242 м²",
    city: "Москва",
    name: "Таунхаус",
    background: townhouseImage,
    link: "/gallery/townhouse",
  },
  {
    id: 3,
    metrs: "78 м²",
    city: "Санкт-Петербург",
    name: "ЖК «Familia»",
    background: familiaImage,
    link: "/gallery/familia",
  },
  {
    id: 4,
    metrs: "405 м²",
    city: "Ленинградская область",
    name: "База отдыха",
    background: holidayImage,
    link: "/gallery/holiday",
  },
];

export const realizedWorks = [
  {
    id: 1,
    metrs: "120 м²",
    city: "Санкт-Петербург",
    name: "ЖК «Леонтьевский Мыс»",
    background: leoImage,
    link: "/gallery/leontievsky",
  },
  {
    id: 2,
    metrs: "130 м²",
    city: "Санкт-Петербург",
    name: "ЖК «Мироздание»",
    background: mirImage,
    link: "/gallery/mirozdanie",
  },
  {
    id: 3,
    metrs: "100 м²",
    city: "Санкт-Петербург",
    name: "ЖК «Аристократ»",
    background: aristoImage,
    link: "/gallery/aristokrat",
  },
  {
    id: 4,
    metrs: "250 м²",
    city: "Ленинградская область",
    name: "Двухэтажная квартира в Пушкине",
    background: pushkinImage,
    link: "/gallery/pushkin",
  },
];

export const points = [
  {
    id: 1,
    first: "Мы подберем для Вас дизайнера, исходя из ваших потребностей",
    second:
      "Назначим управляющего проектом, который подберет всю команду для реализации: строители, смежные подрядчики и поставщики",
  },
  {
    id: 2,
    first:
      "Предложим планировочное решение и концептуальные решения для вашей квартиры",
    second: "Согласуем план производственных работ с УК жилого комплекса",
  },
  {
    id: 3,
    first: "Разработаем 3D визуализацию будущего интерьера",
    second: "Выполним строительно-монтажные работы с соответствии с графиком:",
    list: [
      "прокладка инженерных сетей и монтаж перегородок",
      "черновой этап отделки",
      "предчистовой этап отделки",
      "чистовой этап отделки",
      "установка и сборка мебели",
      "сдача объекта",
    ],
  },
  {
    id: 4,
    first: "Соберем комплектацию отделочных материалов и предметов интерьера",
    second: "Скоординируем работы всех исполнителей и поставщиков",
  },
  {
    id: 5,
    first: "Спроектируем техническую рабочую документацию и инженерный проект",
    second: "Обеспечим еженедельные совещания, фото и видеоотчеты",
  },
  {
    id: 6,
    first:
      "Подготовим объект к началу ремонтных работ: составим бюджет проекта, смету на ремонт и график реализации проекта",
    second: "Подготовим объект к сдаче, проведем клининг",
  },
];

export const persons = [
  {
    id: 1,
    name: "Владислав",
    position: "Основатель компании",
    image: worker1,
    imageGood: workerGood1,
  },
  {
    id: 2,
    name: "Дарья",
    position: "Исполнительный директор",
    image: worker2,
    imageGood: workerGood3,
    altImage: workerGood2,
    altName: "Сергей",
    altPosition: "Управляющий стройкой",
  },
  {
    id: 3,
    name: "Сергей",
    position: "Управляющий стройкой",
    image: worker3,
    imageGood: workerGood2,
    altImage: workerGood3,
    altName: "Дарья",
    altPosition: "Исполнительный директор",
  },
  {
    id: 4,
    name: "Денис",
    position: "Управляющий стройкой",
    image: worker4,
    imageGood: workerGood4,
  },
  {
    id: 5,
    name: "Софья",
    position: "Дизайнер",
    image: worker5,
    imageGood: workerGood5,
  },
  {
    id: 6,
    name: "Юлия",
    position: "Дизайнер",
    image: worker6,
    imageGood: workerGood6,
  },
  {
    id: 7,
    name: "Ольга",
    position: "Дизайнер",
    image: worker7,
    imageGood: workerGood7,
  },
  {
    id: 8,
    name: "Юлия",
    position: "Дизайнер",
    image: worker8,
    imageGood: workerGood8,
  },
  {
    id: 9,
    name: "Алена",
    position: "Дизайнер",
    image: worker9,
    imageGood: workerGood9,
  },
  {
    id: 10,
    name: "Антонина",
    position: "Дизайнер",
    image: worker10,
    imageGood: workerGood10,
  },
  {
    id: 11,
    name: "Дарья",
    position: "Визуализатор",
    image: worker11,
    imageGood: workerGood11,
  },
  {
    id: 12,
    name: "Юлия",
    position: "Дизайнер",
    image: worker12,
    imageGood: workerGood12,
  },
  {
    id: 13,
    name: "Алина",
    position: "Дизайнер",
    image: worker13,
    imageGood: workerGood13,
  },
  {
    id: 14,
    name: "Елена",
    position: "Дизайнер",
    image: worker14,
    imageGood: workerGood14,
  },
  {
    id: 15,
    name: "Анастасия",
    position: "Визуализатор",
    image: worker15,
    imageGood: workerGood15,
  },
  {
    id: 16,
    name: "Инна",
    position: "Визуализатор",
    image: worker16,
    imageGood: workerGood16,
  },
  {
    id: 17,
    name: "Анна",
    position: "Дизайнер",
    image: worker17,
    imageGood: workerGood17,
  },
  {
    id: 18,
    name: "Константин",
    position: "Инженер-сметчик",
    image: worker18,
    imageGood: workerGood18,
  },
  {
    id: 19,
    name: "Сергей",
    position: "Производитель работ",
    image: worker19,
    imageGood: workerGood19,
  },
  {
    id: 20,
    name: "Олег",
    position: "Производитель работ",
    image: worker20,
    imageGood: workerGood20,
  },
  {
    id: 21,
    name: "Андрей",
    position: "Производитель работ",
    image: worker21,
    imageGood: workerGood21,
  },
];

export const personsMobile = [
  {
    id: 1,
    name: "Владислав",
    position: "Основатель компании",
    image: worker1,
    imageGood: workerGood1,
  },
  {
    id: 2,
    name: "Сергей",
    position: "Управляющий стройкой",
    image: worker3,
    imageGood: workerGood2,
    altName: "Дарья",
    altPosition: "Исполнительный директор",
  },
  {
    id: 3,
    name: "Дарья",
    position: "Исполнительный директор",
    image: worker2,
    imageGood: workerGood3,
    altName: "Сергей",
    altPosition: "Управляющий стройкой",
  },
  {
    id: 4,
    name: "Денис",
    position: "Управляющий стройкой",
    image: worker4,
    imageGood: workerGood4,
  },
  {
    id: 5,
    name: "Софья",
    position: "Дизайнер",
    image: worker5,
    imageGood: workerGood5,
  },
  {
    id: 6,
    name: "Юлия",
    position: "Дизайнер",
    image: worker6,
    imageGood: workerGood6,
  },
  {
    id: 7,
    name: "Ольга",
    position: "Дизайнер",
    image: worker7,
    imageGood: workerGood7,
  },
  {
    id: 8,
    name: "Юлия",
    position: "Дизайнер",
    image: worker8,
    imageGood: workerGood8,
  },
  {
    id: 9,
    name: "Константин",
    position: "Инженер-сметчик",
    image: worker18,
    imageGood: workerGood18,
  },
  {
    id: 10,
    name: "Сергей",
    position: "Производитель работ",
    image: worker19,
    imageGood: workerGood19,
  },
  {
    id: 11,
    name: "Алена",
    position: "Дизайнер",
    image: worker9,
    imageGood: workerGood9,
  },
];

export const drumSettings = [
  {
    id: 1,
    name: "Дизайнер",
    params: [
      "Создает дизайн-проект и ведет авторский надзор.",
      "Художественно оформляет интерьер. Подбирает поставщиков, проверяет договоры и счета, составляет график закупок.",
    ],
    images: [
      worker5,
      worker6,
      worker7,
      worker8,
      worker9,
      worker10,
      worker12,
      worker13,
      worker14,
      worker17,
    ],
  },
  {
    id: 2,
    name: "Инженер-сметчик",
    params: [
      "Составляет смету на ремонт.",
      "Отвечает за техническую составляющую проекта – инженерные сети и конструктивные решения. Проверяет корректность всех чертежей.",
    ],
    images: [worker18],
  },
  {
    id: 3,
    name: "Управляющий проектом",
    params: [
      "Контролирует исполнение графика стройки, затраты по проекту и соблюдении сроков реализации.",
      "Организует доставку материалов согласно графику стройки. Принимает товар на объекте и проверяет на наличие брака.",
    ],
    images: [worker3, worker4],
  },
  {
    id: 4,
    name: "Производитель работ",
    params: [
      "Обеспечивает строительные процессы на объекте: материалами, инструментами, исполнителями. Подбирает и организовывает исполнителей согласно графику стройки.",
    ],
    images: [worker19, worker20, worker21],
  },
  {
    id: 5,
    name: "Визуализатор",
    params: [
      "Создает трехмерные компьютерные визуализации интерьера жилых пространств.",
    ],
    images: [worker11, worker15],
  },
];

export const advantages = [
  {
    id: 1,
    title: "Гарантия 5 лет по договору",
    text: "Гарантия на работы с технической поддержкой заказчика даже после окончания гарантийного срока.",
  },
  {
    id: 2,
    title: "Приемка квартиры у застройщика",
    text: "Проверим, что квартира действительно соответствует всем требованиям, заявленным в документах.",
  },
  {
    id: 3,
    title: "Обоснованная стоимость всех видов услуг",
    text: "Начиная с проектных изысканий и заканчивая декорированием, с гарантией неизменности цены.",
  },
  {
    id: 4,
    title: "Хорошие скидки",
    text: "Гарантируем реальные скидки на строительные и отделочные материалы, мебель, текстиль и аксессуары, используемые в проекте. Скидки у партнеров до 20%.",
  },
  {
    id: 5,
    title: "Все решения в одном месте",
    text: "От согласования перепланировки и дизайн-проекта до мебели и декора. Экономим ваше время и силы.",
  },
  {
    id: 6,
    title: "Большой штат узкопрофильных специалистов",
    text: "Наша команда – это дизайнеры, архитекторы, сметчики, строители, прорабы, узкопрофильные мастера. У всех сотрудников профильное образование.",
  },
  {
    id: 7,
    title: "Соблюдение сроков",
    text: "Гарантируем неукоснительное соблюдение сроков и графика реализации проекта, независимо от его сложности и объема.",
  },
  {
    id: 8,
    title: "Ремонт квартиры «под тапочки»",
    text: "Делаем честный ремонт любой квартиры под ключ по принципу «заезжай и живи» без ненужных материалов и лишних технических решений.",
  },
];

export const advantagesServices = [
  {
    id: 1,
    title: "Экономия времени",
    text: "Экономия времени и средств на привлечение сторонних организаций и специалистов, минимизация общения с исполнителями.",
  },
  {
    id: 2,
    title: "Контроль процессов",
    text: "Контроль всех процессов на объекте со стороны подрядчика, отсутствие необходимости “вникать в проблемы”.",
  },
  {
    id: 3,
    title: "Управление проектом",
    text: "Профессиональнлое управление проектом, исключающее как технические ошибки, так и просчеты планирования.",
  },
  {
    id: 4,
    title: "Полная ответственность",
    text: "Единолично несем ответственность за конечный результат реализации проекта.",
  },
];

export const advantagesSupervision = [
  {
    id: 1,
    title: "Результат “как на картинке”",
    text: "Дизайнер проконтролирует чтобы выполненный ремонт соответствовал изображениям дизайн-проекта.",
  },
  {
    id: 2,
    title: "Экономия времени",
    text: "Контроль всех закупок комплектации объекта, контроль поставщиков, организация доставок, работа с рекламациями.",
  },
  {
    id: 3,
    title: "Третье преимущество",
    text: "Профессиональнлое управление проектом, исключающее как технические ошибки, так и просчеты планирования.",
  },
];

export const quizSteps = [
  {
    number: 1,
    question: "Какой у вас объект?",
    param: "objectType",
    answers: [
      "Квартира новостройка",
      "Квартира старый фонд",
      "Таунхаус",
      "Дом",
    ],
  },
  {
    number: 2,
    question: "Какая площадь помещения?",
    param: "area",
    answers: [
      "До 80 м²",
      "От 80 до 150 м²",
      "От 150 до 300 м²",
      "Более 300 м²",
    ],
  },
  {
    number: 3,
    question: "Какой стиль интерьера вам нравится?",
    param: "style",
    answers: [
      "Современный",
      "Классический",
      "Минимализм",
      "Скандинавский",
      "Хай-тек",
      "Пока не знаю",
    ],
  },
  {
    number: 4,
    question: "Когда вы планируете закончить ремонт?",
    param: "completionTime",
    answers: [
      "На новый год",
      "К 1 сентября",
      "Ко дню рождения",
      "Через год и более",
      "Сроки не важны",
    ],
  },
];

export const feedbacks = [
  {
    id: 1,
    text: "С трудом, но уговорила своего мужа на ремонт. Компанию подыскала сама. Наткнулась на ребят, которые уже 18 лет в бизнесе, дают гарантию на 5 лет. Нам нужен был ремонт квартиры под ключ. Всё сделали в сроки, как и обещали. Дизайн делала компания, нам очень понравилось. Квартиру просто не узнать. Очень довольны. Да, вложились мы хорошо, но каждая затраченная копейка окупилась. Рекомендую.",
    name: "Виктория",
    object: "ЖК “Familia”",
    city: "Санкт-Петербург",
  },
  {
    id: 2,
    text: "С трудом, но уговорила своего мужа на ремонт. Компанию подыскала сама. Наткнулась на ребят, которые уже 18 лет в бизнесе, дают гарантию на 5 лет. Нам нужен был ремонт квартиры под ключ. Всё сделали в сроки, как и обещали. Дизайн делала компания, нам очень понравилось. Квартиру просто не узнать. Очень довольны. Да, вложились мы хорошо, но каждая затраченная копейка окупилась. Рекомендую.",
    name: "Виктория",
    object: "ЖК “Familia”",
    city: "Санкт-Петербург",
  },
  {
    id: 3,
    text: "С трудом, но уговорила своего мужа на ремонт. Компанию подыскала сама. Наткнулась на ребят, которые уже 18 лет в бизнесе, дают гарантию на 5 лет. Нам нужен был ремонт квартиры под ключ. Всё сделали в сроки, как и обещали. Дизайн делала компания, нам очень понравилось. Квартиру просто не узнать. Очень довольны. Да, вложились мы хорошо, но каждая затраченная копейка окупилась. Рекомендую.",
    name: "Виктория",
    object: "ЖК “Familia”",
    city: "Санкт-Петербург",
  },
  {
    id: 4,
    text: "С трудом, но уговорила своего мужа на ремонт. Компанию подыскала сама. Наткнулась на ребят, которые уже 18 лет в бизнесе, дают гарантию на 5 лет. Нам нужен был ремонт квартиры под ключ. Всё сделали в сроки, как и обещали. Дизайн делала компания, нам очень понравилось. Квартиру просто не узнать. Очень довольны. Да, вложились мы хорошо, но каждая затраченная копейка окупилась. Рекомендую.",
    name: "Виктория",
    object: "ЖК “Familia”",
    city: "Санкт-Петербург",
  },
  {
    id: 5,
    text: "С трудом, но уговорила своего мужа на ремонт. Компанию подыскала сама. Наткнулась на ребят, которые уже 18 лет в бизнесе, дают гарантию на 5 лет. Нам нужен был ремонт квартиры под ключ. Всё сделали в сроки, как и обещали. Дизайн делала компания, нам очень понравилось. Квартиру просто не узнать. Очень довольны. Да, вложились мы хорошо, но каждая затраченная копейка окупилась. Рекомендую.",
    name: "Виктория",
    object: "ЖК “Familia”",
    city: "Санкт-Петербург",
  },
];

export const questions = [
  {
    question: "Что входит в дизайн-проект?",
    answer:
      "Дизайн-проект включает в себя разработку концепции интерьера, планировочные решения, подбор материалов, мебели и декоративных элементов, а также создание чертежей и схем для строителей. В проект также входят визуализации, которые помогут вам увидеть, как будет выглядеть конечный результат.",
  },
  {
    question: "Кто работает над дизайн-проектом?",
    answer:
      "Над дизайн-проектом работает команда профессионалов: дизайнер интерьера, архитектор, визуализатор, а также технические специалисты, такие как инженеры, если проект требует сложных решений.",
  },
  {
    question:
      "Чем услуга «дизайн-проект» отличается от услуги «интерьер квартиры под ключ»?",
    answer:
      "Услуга «дизайн-проект» включает разработку всех аспектов будущего интерьера, но не включает в себя реализацию. «Интерьер квартиры под ключ» — это комплексная услуга, которая включает в себя не только создание дизайн-проекта, но и его полное воплощение: от закупки материалов до проведения строительных и отделочных работ.",
  },
  {
    question: "Я не знаю, какой стиль хочу видеть в своём доме. Что делать?",
    answer:
      "Начните с просмотра различных примеров интерьеров, чтобы определить, что вам нравится. Обратитесь к дизайнеру, который предложит несколько вариантов стилей на основе ваших предпочтений и функциональных требований. Вместе вы сможете выбрать тот стиль, который вам ближе всего.",
  },
  {
    question: "Зачем нужен авторский надзор?",
    answer:
      "Авторский надзор необходим для того, чтобы контролировать соответствие проводимых работ утвержденному дизайн-проекту. Дизайнер следит за тем, чтобы все материалы, мебель и декоративные элементы соответствовали проекту, а также оперативно вносит необходимые изменения в процессе реализации.",
  },
  {
    question: "Для чего нужна комплектация объекта?",
    answer:
      "Комплектация объекта — это услуга по подбору и закупке всех необходимых материалов, мебели и декоративных элементов для вашего интерьера. Это помогает сэкономить время и избежать ошибок при выборе и доставке материалов, а также обеспечить полное соответствие проекта.",
  },
  {
    question: "Сколько вариантов планировок вы делаете?",
    answer:
      "Обычно предоставляется 2-3 варианта планировочных решений, которые соответствуют вашим запросам и требованиям. Это позволяет выбрать оптимальный вариант, учитывая все пожелания и функциональные задачи.",
  },
  // {
  //   question: "Успеете ли вы создать полный проект за 1 месяц?",
  //   answer:
  //     "Контролирует исполнение графика стройки, затраты по проекту и соблюдении сроков реализации",
  // },
  // {
  //   question:
  //     "Есть ли у вас проверенные строительные бригады, которым можно доверять?",
  //   answer:
  //     "Контролирует исполнение графика стройки, затраты по проекту и соблюдении сроков реализации",
  // },
  // {
  //   question: "Входят ли в состав рабочей документации инженерные проекты?",
  //   answer:
  //     "Контролирует исполнение графика стройки, затраты по проекту и соблюдении сроков реализации",
  // },
  // {
  //   question:
  //     "Можно ли начать ремонт параллельно с разработкой дизайн-проекта?",
  //   answer:
  //     "Контролирует исполнение графика стройки, затраты по проекту и соблюдении сроков реализации",
  // },
  // {
  //   question:
  //     "Если еще не получили ключи от квартиры, можно ли начать работу над проектом?",
  //   answer:
  //     "Контролирует исполнение графика стройки, затраты по проекту и соблюдении сроков реализации",
  // },
  // {
  //   question: "Если мне не понравится работа дизайнера?",
  //   answer:
  //     "Контролирует исполнение графика стройки, затраты по проекту и соблюдении сроков реализации",
  // },
  // {
  //   question: "Занимаетесь ли вы закупками под ключ?",
  //   answer:
  //     "Контролирует исполнение графика стройки, затраты по проекту и соблюдении сроков реализации",
  // },
];

export const designPlans = [
  {
    number: 1,
    title: "Планировочное решение",
    plans: filterImages(plans, []),
  },
  {
    number: 2,
    title: "Технические чертежи",
    plans: filterImages(drawings, []),
  },
  {
    number: 3,
    title: "3D-визуализация",
    plans: filterImages(visualizations, []),
  },
  {
    number: 4,
    title: "Спецификация",
    plans: filterImages(specification, []),
  },
];

export const sliderImages = {
  first: filterImages(plans, []),
  second: filterImages(drawings, []),
  third: filterImages(visualizations, []),
  fourth: filterImages(specification, []),
};

export const factors = [
  { number: 1, factor: "Тип помещения" },
  { number: 2, factor: "Удаленность объекта от города" },
  { number: 3, factor: "Количество чертежей и визуализаций" },
  { number: 4, factor: "Метраж помещения" },
  { number: 5, factor: "Отделочные материалы и нестандартные изделия и узлы" },
  { number: 6, factor: "Сроки ремонта" },
  { number: 7, factor: "Согласование перепланировки" },
  { number: 8, factor: "Наличие инженерного проекта" },
  {
    number: 9,
    factor: "Внутренние правила производственных работ в ЖК",
    factorAlt: "Внутренние правила производственных работ в коттеджном посёлке",
  },
];

export const patterns = [
  {
    id: 1,
    title: "Получение технического задания",
    text: "Профессиональное управление проектом, исключающее как технические ошибки, так и просчеты планирования",
    image: pattern1,
    clickable: false,
  },
  {
    id: 2,
    title: "Составление графика реализации",
    text: "Профессиональное управление проектом, исключающее как технические ошибки, так и просчеты планирования",
    image: pattern2,
    clickable: true,
  },
  {
    id: 3,
    title: "Разработка инженерного проекта и проекта производства работ",
    text: "Профессиональное управление проектом, исключающее как технические ошибки, так и просчеты планирования",
    image: pattern3,
    clickable: true,
  },
  {
    id: 4,
    title: "Составление общего бюджета проекта",
    text: "Профессиональное управление проектом, исключающее как технические ошибки, так и просчеты планирования",
    image: pattern4,
    clickable: true,
  },
  {
    id: 5,
    title: "Подготовка объекта к ремонту",
    text: "Профессиональное управление проектом, исключающее как технические ошибки, так и просчеты планирования",
    image: pattern5,
    clickable: false,
  },
  {
    id: 6,
    title: "Организация производственных работ",
    text: "Профессиональное управление проектом, исключающее как технические ошибки, так и просчеты планирования",
    image: pattern6,
    clickable: false,
  },
  {
    id: 7,
    title: "Проведение производственных совещаний",
    text: "Профессиональное управление проектом, исключающее как технические ошибки, так и просчеты планирования",
    image: pattern7,
    clickable: false,
  },
  {
    id: 8,
    title: "Организация технического надзора",
    text: "Профессиональное управление проектом, исключающее как технические ошибки, так и просчеты планирования",
    image: pattern8,
    clickable: false,
  },
  {
    id: 9,
    title: "Обучение эксплуатации",
    text: "Профессиональное управление проектом, исключающее как технические ошибки, так и просчеты планирования",
    image: pattern9,
    clickable: false,
  },
];

export const patternsSupervision = [
  {
    id: 1,
    title: "Выезды на объект",
    text: "Дизайнер посещает объект один в неделю, при необходимости чаще. Проведение совещаний с командой и управляющим стройкой",
    image: pattern1,
    clickable: false,
  },
  {
    id: 2,
    title: "Контроль хода приемных работ",
    text: "Проверка в процессе ремонта на соответствие всех выполненных работ строителей и всех решений в дизайн-проекте",
    image: pattern2,
    clickable: true,
  },
  {
    id: 3,
    title: "Внесение необходимых правок в чертежи",
    text: "Оперативная доработка чертежей для строителей, если в ходе ремонта в проект вносятся изменения",
    image: pattern3,
    clickable: true,
  },
  {
    id: 4,
    title: "Сопровождение закупок",
    text: "Общение с поставщиками. Контроль закупок всех чистовых материалов, мебели, декора. Проверка качества закупленных материалов и оборудования. Контроль всех оплат и своевременную поставку необходимого на объект",
    image: pattern4,
    clickable: true,
  },
];
