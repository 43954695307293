import "./Questions.css";
import React, { useState } from "react";
import { Accordion, Panel } from "rsuite";

import openIcon from "../../images/Main/union.svg";
import { questions } from "../../utils/constants";
import "rsuite/dist/rsuite-no-reset.min.css";

function Questions({ onCalcClick, onFeedbackClick }) {
  const [activeKey, setActiveKey] = useState(null);

  const handleSelect = (key) => {
    setActiveKey(activeKey === key ? null : key); // Открытие/закрытие панели
  };

  return (
    <section className="questions">
      <h2 className="questions__title">Вопросы и ответы</h2>
      <Accordion activeKey={activeKey} onSelect={handleSelect}>
        {questions.map((data, index) => (
          <Panel
            key={index}
            eventKey={index}
            className="questions__container"
            header={
              <div className="questions__title-container">
                <h3 className="question__question">{data.question}</h3>
                <button
                  type="button"
                  className={`button questions__button ${
                    activeKey === index ? "questions__button_active" : ""
                  }`}
                >
                  <img
                    src={openIcon}
                    className={`question__open-image ${
                      activeKey === index ? "questions__open-image_active" : ""
                    }`}
                    alt="Значок вверх"
                  />
                </button>
              </div>
            }
          >
            <p className="questions__answer">{data.answer}</p>
          </Panel>
        ))}
      </Accordion>
    </section>
  );
}

export default Questions;
