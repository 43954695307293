import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Gallery.css";
import LoadableImage from "../LoadableImage/LoadableImage";
import { realizedGallery, designGallery } from "../../utils/gallery";

const Gallery = ({ gallery, small }) => {
  const [filter, setFilter] = useState(null);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);

    // Устанавливаем фильтр на основе текущего маршрута
    if (location.pathname === "/gallery/realized") {
      setFilter("realized");
    } else if (location.pathname === "/gallery/design") {
      setFilter("design");
    } else {
      setFilter("all");
    }
  }, [location.pathname]);

  const filteredGallery = () => {
    if (filter === "realized") {
      return realizedGallery;
    }
    if (filter === "design") {
      return designGallery;
    }
    return gallery;
  };

  const getProjectClass = (index) => {
    if (index === 0) {
      return "";
    }
    const positionInCycle = (index - 1) % 4;
    if (positionInCycle === 0 || positionInCycle === 1) {
      return "project_type_big";
    }
    return "";
  };

  return (
    <section className={`gallery ${small ? "gallery_type_small" : ""}`}>
      <h2 className="gallery__title">Галерея работ</h2>
      {!small && (
        <p className="gallery__text">
          Вдохновляющие интерьеры для современной жизни
        </p>
      )}
      {!small && (
        <div className="gallery__buttons">
          <Link
            to="/gallery"
            className={`gallery__button ${
              filter === "all" ? "gallery__button_active" : ""
            }`}
            onClick={() => setFilter("all")}
          >
            Все проекты
          </Link>
          <Link
            to="/gallery/realized"
            className={`gallery__button ${
              filter === "realized" ? "gallery__button_active" : ""
            }`}
            onClick={() => setFilter("realized")}
          >
            Реализованные объекты
          </Link>
          <Link
            to="/gallery/design"
            className={`gallery__button ${
              filter === "design" ? "gallery__button_active" : ""
            }`}
            onClick={() => setFilter("design")}
          >
            Дизайн интерьеров квартир
          </Link>
        </div>
      )}
      <div
        className={`gallery__list ${small ? "gallery__list_type_small" : ""}`}
      >
        {filteredGallery().map((project, index) => (
          <article
            className={`project ${getProjectClass(index)}`}
            key={project.id}
          >
            <Link
              className="project__link"
              to={`/gallery/${project.name}`}
              state={{ from: location.pathname }}
            >
              <LoadableImage
                src={project.intro}
                className="project__image"
                alt={project.apart}
              />
              <div className="project__info">
                <h3 className="project__title">
                  {project.metrs} м², {project.location}
                </h3>
                <p className="project__text">{project.apart}</p>
              </div>
            </Link>
          </article>
        ))}
      </div>
      {small && (
        <a className="button gallery__link" href="/gallery">
          Смотреть все проекты
        </a>
      )}
    </section>
  );
};

export default Gallery;
