import React from "react";
import "./Hall.css";
import { Link, useLocation } from "react-router-dom";

import LoadableImage from "../LoadableImage/LoadableImage";

const Hall = ({
  title,
  projects,
  buttonTitle,
  text,
  handleOpenPopupRequest,
}) => {
  const location = useLocation();
  return (
    <section className="hall">
      <h2 className="section-title">{title}</h2>
      <div className="works">
        {projects.map((project) => (
          <article key={project.id} className="work">
            <LoadableImage
              src={project.background}
              className="work__image"
              alt={project.name}
            />
            <div className="work__container">
              <h3 className="work__title">
                <span style={{ fontWeight: 600 }}>{project.metrs}</span>,{" "}
                {project.city}
              </h3>
              <h3 className="work__title">{project.name}</h3>
              <Link
                className="button button_type_glasses"
                to={project.link}
                state={{ from: location.pathname }}
              >
                Подробнее
              </Link>
            </div>
          </article>
        ))}
        <div
          className="works__overlay"
          style={
            text
              ? {
                  background:
                    "linear-gradient(0deg, rgba(28,28,28,1) 5%, rgba(0,0,0,0) 100%)",
                  height: 498,
                  paddingTop: 100,
                  boxSizing: "border-box",
                }
              : {}
          }
        >
          {text && (
            <p className="works__text">
              Лучше один раз посмотреть настоящий объект, чем 10 обзоров на
              разных сайтах
            </p>
          )}
          {text ? (
            <button
              className="button"
              onClick={() => {
                handleOpenPopupRequest();
              }}
            >
              {buttonTitle}
            </button>
          ) : (
            <a className="button" href="/gallery">
              {buttonTitle}
            </a>
          )}
        </div>
      </div>
      {text && (
        <button
          className="hall__link"
          onClick={() => {
            handleOpenPopupRequest();
          }}
        >
          {buttonTitle}
        </button>
      )}
    </section>
  );
};

export default Hall;
