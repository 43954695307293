import React, { useState, useEffect } from "react";
import "./ImagePopup.css";
import arrow from "../../images/Main/arrow.svg";

const ImagePopup = ({ images, currentIndex, onClose }) => {
  const [index, setIndex] = useState(currentIndex);

  const handlePrev = () => {
    setIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "ArrowLeft") {
        handlePrev();
      } else if (e.key === "ArrowRight") {
        handleNext();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className="popup-image">
      <div className="popup-image__container" onClick={onClose}>
        {images.length > 1 && (
          <button
            className="image-popup-button image-popup-button--prev"
            onClick={(e) => {
              e.stopPropagation();
              handlePrev();
            }}
          >
            <img
              src={arrow}
              className="popup-image__arrow"
              alt="стрелка влево"
            />
          </button>
        )}
        <img
          src={images[index]}
          alt={`Фото ${index + 1}`}
          className={`image-popup-image ${
            images.length === 1 ? "image-popup-image_type_big" : ""
          } `}
          onClick={(e) => e.stopPropagation()}
        />
        {images.length > 1 && (
          <button
            className="image-popup-button image-popup-button--next"
            onClick={(e) => {
              e.stopPropagation();
              handleNext();
            }}
          >
            <img
              src={arrow}
              className="popup-image__arrow"
              alt="стрелка вправо"
            />
          </button>
        )}
        {images.length > 1 && (
          <div className="image-popup-indicator">
            {index + 1}/{images.length}
          </div>
        )}
        <button className="popup-image__close-button">
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="popup-image__close-button-image"
          >
            <path
              d="M36 12L12 36M12 12L36 36"
              stroke="white"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default ImagePopup;
