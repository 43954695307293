import React, { useRef } from "react";
import "./InputWithError.css";

const InputWithError = ({
  name,
  value,
  onChange,
  onKeyDown,
  placeholder,
  pattern,
  isError,
  messageError,
  isInputsDisabled,
  type = "text",
  required = false,
}) => {
  const inputRef = useRef(null);

  return (
    <div className={`inputs-container`}>
      <div
        className={`input-container  ${
          isError ? `input-container_type_error` : ""
        }`}
      >
        <input
          className={`input ${isError ? `input_type_error` : ""}`}
          name={name}
          ref={inputRef}
          type={type}
          value={value || ""}
          onKeyDown={onKeyDown}
          onChange={onChange}
          placeholder={placeholder}
          pattern={pattern}
          disabled={isInputsDisabled}
          required={required}
        />
      </div>
      <span className={`input-error ${isError ? "input-error_active" : ""}`}>
        {isError &&
          (messageError === `Введите данные в указанном формате.`
            ? `Неверный формат.`
            : messageError)}
      </span>
    </div>
  );
};

export default InputWithError;
