import "./Preloader.css";
import React from "react";
import preloaderImage from "../../images/Preloader/preloader.svg";

const Preloader = ({ isOpen }) => {
  return (
    <section className={`preloader ${!isOpen ? "preloader_hidden" : ""}`}>
      <div className="preloader__container">
        <img
          className="preloader__image"
          src={preloaderImage}
          alt="Логотип компании"
        />
      </div>
      <svg
        width="1174"
        height="1085"
        viewBox="0 0 1174 1085"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="preloader__overlay"
      >
        <path
          d="M943.363 273.838L662.096 111.448L251.096 823.321L532.363 985.71L943.363 273.838Z"
          fill="#1C1C1C"
        />
        <path
          d="M1091.96 360L943 274L532 985.873L680.956 1071.87L1091.96 360Z"
          fill="url(#paint0_linear_1478_8010)"
        />
        <path
          d="M664.956 113L516 27L105 738.873L253.956 824.873L664.956 113Z"
          fill="url(#paint1_linear_1478_8010)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1478_8010"
            x1="737.5"
            y1="629.936"
            x2="886.456"
            y2="715.936"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1C1C1C" />
            <stop offset="1" stopColor="#1C1C1C" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1478_8010"
            x1="459.457"
            y1="468.936"
            x2="310.5"
            y2="382.936"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1C1C1C" />
            <stop offset="1" stopColor="#1C1C1C" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </section>
  );
};

export default Preloader;
