import React from "react";
import styles from "./LoadableImage.module.css";
import cn from "classnames";
import useOnScreen from "../../hooks/useOnScreen";

const LoadableImage = (props) => {
  const { src, className, alt, onLoad = () => {} } = props;
  const [isLoaded, setIsLoaded] = React.useState(false);
  const imageRef = React.useRef(null);
  const containerRef = React.useRef(null);

  const offset = window.innerHeight * 1.3;
  const isVisible = useOnScreen(containerRef, `${offset}px`);

  React.useEffect(() => {
    if (!isVisible || isLoaded) {
      return;
    }
    if (imageRef.current) {
      imageRef.current.onload = () => {
        setIsLoaded(true);
        onLoad();
      };
    }
  }, [isVisible, onLoad, isLoaded]);

  return (
    <div
      ref={containerRef}
      className={cn(styles.container, className, {
        [styles.containerLoaded]: isLoaded,
      })}
    >
      {(isVisible || isLoaded) && (
        <img
          ref={imageRef}
          className={cn(styles.image, className, {
            [styles.imageLoaded]: isLoaded,
          })}
          src={src}
          alt={alt}
          onClick={props.onClick}
        />
      )}
    </div>
  );
};

export default LoadableImage;
