import "./Quiz.css";
import React, { useState, useRef, useEffect } from "react";
import { quizSteps } from "../../utils/constants";
import Form from "../Form/Form";

import LoadableImage from "../LoadableImage/LoadableImage";

import dariaImage from "../../images/Main/daria.png";

import useWindowSize from "../../hooks/useWindowSize";

const Quiz = ({ handleOpenPopupRequest }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeRadio, setActiveRadio] = useState({});
  const [selectedAnswer, setSelectedAnswer] = useState({});
  const [heightColumns, setHeightColumns] = useState(null);
  const [isQuizComplete, setIsQuizComplete] = useState(false);
  const quizContainerRef = useRef(null);

  const size = useWindowSize();

  const [data, setData] = useState({});

  const handleCheckWidth = () => {
    if (size.width >= 1440) {
      setHeightColumns(`${quizSteps[activeIndex].answers.length * 84}px`);
    }
    if (size.width <= 1440) {
      setHeightColumns(`${quizSteps[activeIndex].answers.length * 8.4}rem`);
    }
    if (size.width <= 1024) {
      setHeightColumns(`${quizSteps[activeIndex].answers.length * 7.9}rem`);
    }
  };

  useEffect(() => {
    handleCheckWidth();
  }, [size.width, activeIndex]);

  function handleChangeRadio(value, key, number) {
    setData((prevState) => ({
      ...prevState,
      [key]: value,
    }));

    setActiveRadio((prevState) => ({
      ...prevState,
      [key]: value,
    }));

    setSelectedAnswer((prevState) => ({
      ...prevState,
      [activeIndex]: value,
    }));
  }

  function handleNextClick() {
    if (activeIndex < quizSteps.length - 1) {
      setActiveIndex((prevIndex) => prevIndex + 1);
      if (quizContainerRef.current) {
        setTimeout(() => {
          quizContainerRef.current.scrollBy({ left: 400, behavior: "smooth" });
        }, 300);
      }
    } else {
      setIsQuizComplete(true);
    }
  }

  function handleBackClick() {
    setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    if (quizContainerRef.current) {
      setTimeout(() => {
        quizContainerRef.current.scrollBy({ left: -400, behavior: "smooth" });
      }, 300);
    }
  }

  return (
    <section className="quiz">
      <h2
        className={`section-title ${
          isQuizComplete ? "section-title_disactive" : ""
        }`}
      >
        Ответьте на несколько вопросов и получите предварительный расчет
        стоимости вашего проекта
      </h2>
      <div className="quiz__bin">
        <div
          className={`quiz__response ${
            isQuizComplete ? "quiz__response_active" : ""
          }`}
        >
          <h3 className="quiz__response-title">Спасибо за ответы!</h3>
          <p className="quiz__text">
            Остался последний шаг — заполнить личные данные. После отправки
            заявки, в течение рабочего дня персональный менеджер свяжется с Вами
            и предоставит предварительный расчет стоимости Вашего
            дизайн-проекта.
          </p>
        </div>
        <div
          className={`quiz__questions ${
            isQuizComplete ? "quiz__questions_disactive" : ""
          }`}
        >
          <div className="quiz__container">
            <div
              className="quiz__number"
              data-progress={Math.min(quizSteps.length, activeIndex + 1)}
            >
              <span className="quiz__number_active">
                {quizSteps[activeIndex].number}
              </span>
              /{quizSteps.length}
            </div>
            <p className="quiz__title">{quizSteps[activeIndex].question}</p>
          </div>
          <div
            className="quiz__columns"
            style={{
              height: heightColumns,
            }}
            ref={quizContainerRef}
          >
            {quizSteps.map((step, stepIndex) => (
              <div key={stepIndex} className="quiz__column">
                {step.answers.map((answer, answerIndex) => (
                  <label key={answerIndex} className={`radio`}>
                    <input
                      type="radio"
                      name={step.param}
                      value={answer}
                      className="radio__input"
                      onChange={(e) =>
                        handleChangeRadio(
                          e.target.value,
                          e.target.name,
                          step.number
                        )
                      }
                    />
                    <span
                      className={`radio__button ${
                        activeRadio[step.param] === answer
                          ? "radio__button_active"
                          : ""
                      }`}
                    ></span>
                    {answer}
                  </label>
                ))}
              </div>
            ))}
          </div>
          <div className="quiz__buttons">
            <button
              type="button"
              className={`button button_type_glasses quiz__button ${
                activeIndex !== 0 ? `quiz__button_active` : ``
              }`}
              onClick={handleBackClick}
              disabled={!(activeIndex !== 0)}
            >
              Назад
            </button>
            <button
              type="button"
              className={`button button_type_glasses quiz__button_type_next ${
                selectedAnswer[activeIndex] ? `quiz__button_active` : ``
              }`}
              onClick={handleNextClick}
              disabled={!selectedAnswer[activeIndex]}
            >
              Далее
            </button>
          </div>
        </div>
        <div
          className={`quiz__form ${isQuizComplete ? "quiz__form_active" : ""}`}
        >
          <Form quiz={data} />
        </div>
        <div className={`summary ${isQuizComplete ? "summary_disactive" : ""}`}>
          <div className="summary__person">
            <LoadableImage
              className="summary__image"
              src={dariaImage}
              alt="Фотография Дарьи"
            />
            <div className="summary__info">
              <h3 className="summary__title">Дарья Казарина</h3>
              <p className="summary__subtitle">Исполнительный директор</p>
            </div>
          </div>
          <p className="summary__text">
            Мы реализовали более 500 проектов, включая квартиры, загородные дома
            и коммерческие пространства. <br /> Будущий дизайн вашего интерьера
            будет разрабатывать команда с большим опытом в реализации проектов
            любой сложности.
          </p>
          <div className="summary__container">
            <p className="summary__paragraph">
              Если вам нужна консультация,
              <br /> я свяжусь с вами удобным для вас способом
            </p>
            <button
              className="button summary__button"
              onClick={handleOpenPopupRequest}
            >
              Перезвонить
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Quiz;
