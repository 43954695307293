import "./NotFound.css";
import React from "react";
import notFoundImage from "../../images/notFound/404.png";

const NotFound = () => {
  return (
    <section className="not-found">
      <div className="not-found__container">
        <img
          src={notFoundImage}
          className="not-found__image"
          alt="Изображение 404"
        />
        <h3 className="not-found__title">
          Ошибка 404: Страница не найдена, но Вы можете вернуться на{" "}
          <a className="not-found__link" href="/">
            {" "}
            главную
          </a>
        </h3>
        <a className="button" href="/">
          Вернуться на главную
        </a>
      </div>
    </section>
  );
};

export default NotFound;
