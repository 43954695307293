import "./Patterns.css";
import React, { useState } from "react";
import LoadableImage from "../LoadableImage/LoadableImage";
import ImagePopup from "../ImagePopup/ImagePopup";

const Patterns = ({ patterns }) => {
  const [popupImage, setPopupImage] = useState(null);

  const openImagePopup = (image) => {
    setPopupImage(image);
  };

  const closeImagePopup = () => {
    setPopupImage(null);
  };

  const handleImageClick = (image) => {
    openImagePopup(image);
  };

  return (
    <section className="patterns">
      <h2 className="service__question-title">Схема работы</h2>
      <ul className="patterns__list">
        {patterns.map((pattern) => (
          <li className="pattern" key={pattern.id}>
            <div
              className="pattern__image-container"
              onClick={() =>
                pattern.clickable ? handleImageClick(pattern.image) : null
              }
              style={{ cursor: pattern.clickable ? "pointer" : "default" }}
            >
              <LoadableImage
                src={pattern.image}
                className={`pattern__image`}
                alt="Рукопись «Ваше новое качество жизни»"
              />
              {pattern.clickable && (
                <span className="pattern__zoom">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="pattern__zoom-image"
                  >
                    <path
                      d="M21 21L16.7 16.7M11 7V15M15 11H7M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              )}
            </div>

            <div className="pattern__container">
              <div className="pattern__info">
                <p className="pattern__title">{pattern.id + "."}</p>
                <h4 className="pattern__title">{pattern.title}</h4>
              </div>
              <p className="pattern__text">{pattern.text}</p>
            </div>
          </li>
        ))}
      </ul>
      {popupImage !== null && (
        <ImagePopup
          images={[popupImage]}
          currentIndex={0}
          onClose={closeImagePopup}
        />
      )}
    </section>
  );
};

export default Patterns;
