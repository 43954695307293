import "./Builders.css";
import React from "react";
import LoadableImage from "../LoadableImage/LoadableImage";
import buildersImage from "../../images/contracting/builders.jpg";

const Builders = () => {
  return (
    <section className="builders">
      <h2 className="service__question-title">Схема работы</h2>
      <div className="builders__container">
        <LoadableImage
          src={buildersImage}
          className={`builders__image`}
          alt="Команд строителей"
        />
        <p className="builders__image-text">
          Команда СОДА – более 50 человек, узкопрофильных специалистов, которые
          могут реализовать интерьер любой сложности
        </p>
      </div>
      <p className="builders__text">
        Компания на рынке ремонтно-строительных услуг уже больше 20 лет – за это
        время мы разработали систему, исключающую любые проблемы с ремонтом.
      </p>
      <p className="builders__text">
        Мы гарантируем высокие стандарты выполняемых работ и неизменные сроки
        при качественно новом уровне сервиса.
      </p>
    </section>
  );
};

export default Builders;
